import './App.css';
import './vendor/font-awesome/css/all.css';
import './assets/w3.css';
import Main from './components/Main';



function App() {
  return (
    <div>
      
<Main/>
    </div>
  );
}

export default App;
